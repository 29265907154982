import React from 'react'

type TToggleProps = {
	checked: boolean
	onChange: (checked: boolean) => void
}
export const Toggle: React.FC<TToggleProps> = ({ checked, onChange }) => {
	return (
		<label className="my-1 inline-flex cursor-pointer items-center">
			<input
				type="checkbox"
				value={checked}
				className="peer sr-only"
				onChange={(e) => onChange(e.target.checked)}
			/>
			<div className="peer relative h-6 w-11 rounded-full bg-pastel-lavender after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-dark-violet after:bg-white after:transition-all after:content-[''] peer-checked:bg-turquoise-dark peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 rtl:peer-checked:after:-translate-x-full"></div>
		</label>
	)
}
