import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { STORE_KEYS } from '../constants'
import type { TCartProduct } from '../types'
import { customGtagEvent } from '../utils'

type TCartStore = {
	isCartOpen: boolean
	setIsCartOpen: (isOpen: boolean) => void
	cartProducts: TCartProduct[]
	clearCart: () => void
	toggleCart: () => void
	addCartProduct: (product: TCartProduct) => void
	deleteCartProduct: (productId: number) => void
	decrementProductQuantity: (productId: number) => void
}

export const useCartStore = create<TCartStore>()(
	devtools(
		persist(
			(set) => ({
				isCartOpen: false,
				setIsCartOpen: (isOpen: boolean) => set({ isCartOpen: isOpen }),
				toggleCart: () => set((state) => ({ isCartOpen: !state.isCartOpen })),
				cartProducts: [],
				clearCart: () => set({ cartProducts: [] }),
				addCartProduct: (product: TCartProduct) => {
					customGtagEvent('cart-add', {
						message: 'added product to cart',
						product: product.name,
					})
					return set((state) => {
						return { cartProducts: [...state.cartProducts, product] }
					})
				},
				deleteCartProduct: (productId: number) => {
					customGtagEvent('cart-remove', {
						message: 'removed product from cart',
						productId: productId,
					})
					return set((state) => ({
						cartProducts: state.cartProducts.filter(
							(product) => product.id !== productId
						),
					}))
				},
				decrementProductQuantity: (productId: number) =>
					set((state) => {
						const lastIndex = state.cartProducts.findLastIndex(
							(product) => product.id === productId
						)
						const cartProductsCopy = state.cartProducts
						cartProductsCopy.splice(lastIndex, 1)
						return {
							cartProducts: cartProductsCopy,
						}
					}),
			}),
			{
				name: STORE_KEYS.cart, // unique name
				storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
			}
		)
	)
)
