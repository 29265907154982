import React, { useRef, useState } from 'react'
import { Text } from './Text'
import { customGtagEvent } from '../utils'

type ExpandedTextProps = {
	title: string
	description?: string
	children?: React.ReactNode
	isExpandedByDefault?: boolean
	isExpandable?: boolean
	renderToggleButton?: (isToggledOn: boolean) => React.ReactNode
	className?: string
}
export const ExpandedText: React.FC<ExpandedTextProps> = ({
	title,
	description,
	children,
	isExpandedByDefault = false,
	isExpandable = true,
	renderToggleButton,
	className,
}) => {
	const [isToggledOn, setIsToggledOn] = useState(isExpandedByDefault)
	const contentRef = useRef(null)
	return (
		<div
			className={`select-none overflow-hidden ${className} ${
				isExpandable ? 'cursor-pointer' : ''
			}`}
			onClick={() => {
				if (isExpandable) {
					setIsToggledOn((prev) => !prev)
				}
			}}
		>
			<div className="flex select-none items-center justify-between">
				<Text weight="bold" className="mb-2">
					{title}
				</Text>
				{isExpandable &&
					(renderToggleButton ? (
						renderToggleButton(isToggledOn)
					) : (
						<Text
							color="main-gold"
							weight="bold"
							size="sm"
							className="ml-4 flex cursor-pointer select-none items-center text-sm text-main-gold transition-colors md:hover:text-alt-gold"
						>
							{isToggledOn ? 'Zwiń' : 'Rozwiń'}
						</Text>
					))}
			</div>
			<div
				ref={contentRef}
				style={{
					maxHeight: isToggledOn
						? `${contentRef.current.scrollHeight}px`
						: '0px',
				}}
				className="overflow-hidden transition-all duration-500 ease-in-out"
			>
				{children || <Text color="gray2">{description}</Text>}
			</div>
		</div>
	)
}
