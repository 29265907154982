import { Text } from '../ui/Text'
import { CookiesBannerCookieSection } from './CookiesBannerCookieSection'
import React from 'react'

type TCookiesBannerAdjustProps = {
	onChange: (checked: boolean) => void
}
export const CookiesBannerAdjust: React.FC<TCookiesBannerAdjustProps> = ({
	onChange,
}) => {
	return (
		<div>
			<Text color="main-gold" weight="bold" size="lg">
				Dostosuj ustawienia zgód{' '}
			</Text>
			<div className="my-4 text-sm">
				Używamy plików cookie, aby ułatwić Ci poruszanie się po stronie oraz
				zapewnić działanie niektórych funkcji. Szczegółowe informacje na temat
				każdego rodzaju plików cookie znajdziesz poniżej w poszczególnych
				kategoriach zgód. Pliki cookie oznaczone jako „Niezbędne” są
				przechowywane w Twojej przeglądarce, ponieważ są kluczowe dla
				podstawowych funkcjonalności witryny. Korzystamy również z plików cookie
				firm trzecich, które pomagają nam analizować sposób korzystania z
				witryny, zapisywać Twoje preferencje i wyświetlać treści oraz reklamy
				dopasowane do Twoich zainteresowań. Te pliki cookie będą przechowywane
				wyłącznie za Twoją zgodą. Możesz zdecydować, które z tych plików cookie
				chcesz włączyć lub wyłączyć, jednak wyłączenie niektórych z nich może
				wpłynąć na komfort korzystania z witryny.
			</div>
			<div className="my-2 flex flex-col gap-6 border-t border-base-font pt-2">
				<CookiesBannerCookieSection
					sectionTitle="Wymagane"
					isAlwaysActive
					onChange={onChange}
					cookiesList={[
						{
							name: '__stripe_mid',
							description:
								'Ciasteczko używane przez Stripe do procesowania płatności',
						},
						{
							name: 'csrftoken',
							description:
								'Ciasteczko używane do zabezpiecznia strony przed atakami CSRF',
						},
					]}
				/>
				<CookiesBannerCookieSection
					sectionTitle="Analityczne"
					isAlwaysActive={false}
					onChange={onChange}
					cookiesList={[
						{
							name: 'gsID',
							description:
								'Używane do śledzenia sesji użytkownika na stronie w celu analizy ruchu.',
						},
						{
							name: 'sentry-sc',
							description:
								'Służy do monitorowania błędów i wydajności aplikacji przez platformę Sentry.',
						},
						{
							name: 'session',
							description:
								'Przechowuje informacje o bieżącej sesji użytkownika, np. zalogowaniu.',
						},
						{
							name: '_ga',
							description:
								'Służy do generowania anonimowych statystyk dotyczących użytkowania strony (Google Analytics).',
						},
						{
							name: '_ga_*',
							description:
								'Rozszerzenie Google Analytics do śledzenia sesji i działań użytkownika na stronie.',
						},
						{
							name: 'AMP_MKGT_*',
							description:
								'Używane przez Google AMP do śledzenia marketingowego i analizy użytkownika.',
						},
						{
							name: 'AMP_*',
							description:
								'Pomaga w śledzeniu i analizie danych użytkownika w ekosystemie Google AMP.',
						},
					]}
				/>
			</div>
		</div>
	)
}
