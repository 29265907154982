import { useEffect, useState } from 'react'

const CONSENT_KEY = 'analytics_consent'
const CONSENT_DEFAULT_SENT_KEY = 'was_default_consent_sent'

export const useConsent = () => {
	const [consent, setConsent] = useState<string | null>(null) // Initialize as null to avoid SSR mismatch

	const updateConsent = (newConsent) => {
		setConsent(newConsent)
		if (typeof window !== 'undefined') {
			localStorage.setItem(CONSENT_KEY, newConsent)
		}

		// Update Gtag consent
		if (typeof window?.gtag === 'function') {
			window.gtag('consent', 'update', {
				ad_storage: newConsent,
				ad_user_data: newConsent,
				ad_personalization: newConsent,
				analytics_storage: newConsent,
			})
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			// Retrieve consent from local storage
			const storedConsent = localStorage.getItem(CONSENT_KEY)
			setConsent(storedConsent)

			// Check if default consent was sent
			const defaultSent = !!localStorage.getItem(CONSENT_DEFAULT_SENT_KEY)

			// Send default consent if necessary
			if (!defaultSent && typeof window?.gtag === 'function') {
				window.gtag('consent', 'default', {
					ad_storage: 'denied',
					ad_user_data: 'denied',
					ad_personalization: 'denied',
					analytics_storage: 'denied',
				})
				setConsent('denied')
				localStorage.setItem(CONSENT_DEFAULT_SENT_KEY, 'true')
			}
		}
	}, [])

	useEffect(() => {
		// Apply consent on changes
		if (typeof window?.gtag === 'function' && consent) {
			window.gtag('consent', 'update', {
				ad_storage: consent,
				ad_user_data: consent,
				ad_personalization: consent,
				analytics_storage: consent,
			})
		}
	}, [consent])

	return { consent, updateConsent }
}
