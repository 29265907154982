import './src/styles/global.css'
import Layout from './src/ui/Layout'
import React from 'react'
import { initSentry } from './sentry'

const wrapPageElement = ({ element, props }) => {
	if (props.pageResources.component.default.name === 'NotFoundPage') {
		return element
	}
	return <Layout>{element}</Layout>
}

const onClientEntry = () => {
	initSentry()
}

export { wrapPageElement, onClientEntry }
