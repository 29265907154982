import React from 'react'
import { Button } from '../ui/Button'
import { CookiesBannerAdjust } from './CookiesBannerAdjust'
import { useConsent } from '../hooks/consent.hook'

export const CookiesBanner: React.FC = () => {
	const [isAdjustViewVisible, setIsAdjustViewVisible] = React.useState(false)
	const [isGoogleAnalyticsActive, setIsGoogleAnalyticsActive] =
		React.useState(false)
	const { consent, updateConsent } = useConsent()

	if (consent) {
		return null
	}

	return (
		<div
			className={` fixed bottom-0 left-0 right-0 z-10 m-4 flex flex-col items-center gap-4 overflow-auto bg-[#20252A] px-8 py-6 text-xs text-white ${
				isAdjustViewVisible ? 'max-h-[800px] flex-col' : ''
			}`}
		>
			{isAdjustViewVisible ? (
				<CookiesBannerAdjust
					onChange={(value) => setIsGoogleAnalyticsActive(value)}
				/>
			) : (
				<div>
					Informujemy, iż w celu realizacji usług dostępnych w naszym sklepie,
					optymalizacji jego treści oraz dostosowania sklepu do Państwa
					indywidualnych potrzeb korzystamy z informacji zapisanych za pomocą
					plików cookies na urządzeniach końcowych użytkowników. Pliki cookies
					można kontrolować za pomocą ustawień swojej przeglądarki internetowej.
					Dalsze korzystanie z naszego sklepu internetowego, bez zmiany ustawień
					przeglądarki internetowej oznacza, iż użytkownik akceptuje stosowanie
					plików cookies. Więcej informacji zawartych jest w{' '}
					<a
						aria-label="polityka prywatnosci"
						className="inline text-turquoise-dark hover:underline"
						href="https://nefee.b-cdn.net/Polityka%20prywatnos%CC%81ci%20(RODO).doc.pdf"
						target="_blank"
						rel="noreferrer"
					>
						polityce prywatności sklepu
					</a>{' '}
					.
				</div>
			)}
			<div className="flex gap-6">
				<Button
					text={isAdjustViewVisible ? 'Zapisz ustawienia' : 'Dostosuj'}
					className="text-sm"
					onClick={() => {
						if (isAdjustViewVisible) {
							updateConsent(isGoogleAnalyticsActive ? 'granted' : 'denied')
						}

						setIsAdjustViewVisible((prev) => !prev)
					}}
				/>
				<Button
					text="Akceptuje wszystkie"
					className="text-sm"
					onClick={() => {
						updateConsent('granted')
					}}
				/>
				<Button
					text="Odrzucam"
					className="text-sm"
					onClick={() => {
						updateConsent('denied')
					}}
				/>
			</div>
		</div>
	)
}
