import React from 'react'
import { Text } from './Text'
import { Button } from './Button'
import { navigate } from 'gatsby'
import EmptyCartIllustration from '../assets/svgs/empty_cart.svg'
import { customGtagEvent } from '../utils'
import CloseIcon from '../assets/svgs/close.svg'

type CartProps = {
	children?: React.ReactNode
	onClose: () => void
	priceTotal: number
	onCheckout?: () => void
}
export const DesktopCart: React.FC<CartProps> = ({
	children,
	onClose,
	priceTotal,
	onCheckout,
}) => {
	const hasAnyItems = React.Children.count(children) > 0
	return (
		<div
			className="!absolute !right-[12px] !top-[42px] z-20 max-w-[580px] bg-white"
			data-testid="desktop-navbar-cart-modal"
			role="dialog"
			aria-labelledby="desktopCartTitle"
			aria-describedby="desktopCartDescription"
		>
			<div className="flex max-h-[600px] min-h-full flex-col px-4 py-8 md:min-w-[520px] md:bg-white md:p-8 md:shadow-3xl">
				<Text
					weight="bold"
					className="mb-6 flex justify-between text-3xl "
					id="desktopCartTitle"
				>
					Koszyk
					<CloseIcon onClick={onClose} role="button" />
				</Text>
				{hasAnyItems && (
					<div className="flex flex-col gap-8 overflow-auto">{children}</div>
				)}

				{hasAnyItems ? (
					<div className="mb-6 mt-12 flex flex-grow items-end justify-between">
						<Text color="gray2">Suma</Text>
						<Text color="gray2" weight="bold" className="text-3xl">
							{priceTotal} zł
						</Text>
					</div>
				) : (
					<>
						<div className="flex">
							<EmptyCartIllustration
								role="img"
								aria-describedby="empty_kid_magnifier"
							/>
						</div>
						<div className="flex-grow" id="desktopCartDescription">
							<h2 className="mb-4 text-center text-2xl font-semibold text-base-font">
								Pusto tu...
							</h2>
							<p className="text-center font-normal">
								Czas na biżuteryjne odkrycia!
							</p>
							<p className="mb-6 text-center font-normal">
								Twój koszyk czeka na pierwszą perełkę.
							</p>
						</div>
					</>
				)}
				{hasAnyItems && (
					<Button
						onClick={() => {
							onCheckout?.()
							navigate('/kasa')
						}}
						text="Do kasy"
						className="h-[48px]"
					/>
				)}
			</div>
		</div>
	)
}
