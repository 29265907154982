import { ExpandedText } from '../ui/ExpandedText'
import { Text } from '../ui/Text'
import React from 'react'
import { Toggle } from '../ui/Toggle'

type TCookiesBannerCookieSectionProps = {
	sectionTitle: string
	isAlwaysActive: boolean
	cookiesList: { name: string; description: string }[]
	onChange: (checked: boolean) => void
}

export const CookiesBannerCookieSection: React.FC<
	TCookiesBannerCookieSectionProps
> = ({ sectionTitle, isAlwaysActive, cookiesList, onChange }) => {
	return (
		<ExpandedText
			title={sectionTitle}
			className="[&>div>p:first-child]:!text-white"
			renderToggleButton={(isToggledOn) => {
				return (
					<div className="mb-2 flex items-center gap-4">
						{isAlwaysActive ? (
							<p className="font-bold text-red">Zawsze aktywne</p>
						) : (
							<Toggle checked={isToggledOn} onChange={onChange} />
						)}
						<Text
							color="main-gold"
							weight="bold"
							size="sm"
							className="ml-4 flex cursor-pointer select-none items-center text-sm text-main-gold transition-colors md:hover:text-alt-gold"
						>
							{isToggledOn ? 'Zwiń' : 'Rozwiń'}
						</Text>
					</div>
				)
			}}
		>
			<div>
				<ul className="space-y-2 bg-alt-font px-4 py-2">
					{cookiesList.map((cookie) => (
						<li key={cookie.name}>
							<span className="font-bold">{cookie.name}</span>:{' '}
							<span>{cookie.description}</span>
						</li>
					))}
				</ul>
			</div>
		</ExpandedText>
	)
}
